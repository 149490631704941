import React from 'react'
import "./codeblock.css"
import { useEffect, useState , useReducer ,useContext } from "react";
import { useParams } from 'react-router-dom';
import { ACTION_TYPES } from "../../reducer/codeblockActionTypes";
import { INITIAL_STATE, codeblockReducer } from "../../reducer/codeblockReducer";
import {URL} from "../../App"
import roleContext from "../../context/roleContext";
import socketContext from "../../context/socketContext";
import roomContext from "../../context/roomContext";
import Loading from '../../components/Loading';
import axios from 'axios';
import CodeMirror from "@uiw/react-codemirror";
import { javascript } from "@codemirror/lang-javascript";





const CodeBlock = () => {

    const [state, dispatch] = useReducer(codeblockReducer, INITIAL_STATE);
    const [isTrue,setIsTrue] = useState(false);
    const {role} = useContext(roleContext);
    const {socket} = useContext(socketContext);
    const {room} = useContext(roomContext);
    const { id } = useParams();

    const onChange = (value) =>{
        const newVal = value;
        dispatch({ type: ACTION_TYPES.UPDATE_CODE, payload: newVal });
        sendToMentor(value);
    }


    
    const sendToMentor = (value) =>{
        const code = value;
        socket.emit("send_message", { room , code} ); // send the student's code to the codeblock room
        //check if the student's code equals to the solution.
        if(code.trim() === state.codeblock.solution.trim()){

            setIsTrue(true);
        }
    }



    useEffect(() =>{
        socket.on("receive_message", (message) => {
            console.log(message);
                dispatch({ type: ACTION_TYPES.UPDATE_CODE, payload: message.code }); 
        });


    },[socket]);
    
    useEffect(()=> {
 
        const getCodeblock = async () =>{
            
            dispatch({ type: ACTION_TYPES.FETCH_START});
            try {
                const res = await axios.get(`${URL}/api/codeblocks/${id}`)
                dispatch({ type: ACTION_TYPES.FETCH_SUCCESS, payload: res.data })
            } catch (error) {
                console.log(error);
                dispatch({ type: ACTION_TYPES.FETCH_ERROR });
            }
        }
            getCodeblock();
          

    },[id])




    const renderContent = (
        <div className="contentContainer">
            <h1 className="cBlockTitle">{state.codeblock.title}</h1>
            <h3 className="roleTitle">{`You are on ${role} mode`}</h3>
            <h2 className="codeTask">{state.codeblock.code}</h2>
            {role==="student" && 
            <div className="editCodeContainer">
              <div className="editorFrame">
              <CodeMirror
                height="600px"
                width='900px'
                theme="dark"
                extensions={[javascript({ jsx: true })]}
                onChange={onChange}
              />
              </div>
            </div>}
            {role==="mentor" && 
            <div className="codeAreaContainer">
                <div className="editorFrame">
                  <CodeMirror
                    value={state.CodeReceived}
                    height="600px"
                    width='900px'
                    theme="dark"
                    extensions={[javascript({ jsx: true })]}
                    readOnly="true"
                  />
                  </div>
             </div>}
            {isTrue && 
        <div className="successContainer">
            <img src='/assests/smiley.png' alt="Smiley face" style={{ width: '300px', height: '300px' }}/>
            <h3 className="sucessNote">Good Job!</h3>
            </div>}
        </div>
    )
    
    return (
        <div className="codeblock">
            {state.loading ?
            <Loading/> :
            renderContent
            }
        </div>
          )
    }
        

export default CodeBlock;